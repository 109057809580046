import PropTypes from 'prop-types'
import useSuggestBox from './useSuggestBox'

const SuggestBox = ({id, value, onChange, className, placeholder, floating, label}) => {
    const [suggestItems, suggestValue, displayBox, hideSuggestBox, onText, onSelect] = useSuggestBox(value, onChange)

    return (
        <>
            {(!floating && <label htmlFor={id} >{label}</label>)}
            <div className={"dropdown" + (floating ? " form-floating" : "")}>
                <input id={id} className={"dropdown-toggle " + className} value={suggestValue || ''} onChange={e => onText(e.target.value)} onBlur={hideSuggestBox} placeholder={placeholder} data-bs-toggle="dropdown" />
                {(floating && <label htmlFor={id} >{label}</label>)}
                <ul className={displayBox ? "dropdown-menu d-block" : "dropdown-menu"} aria-labelledby={id}>
                    {suggestItems && suggestItems.map(item => (
                        <li key={item.id} tabIndex="-1" id={"dropdown-item-li" + item.id}><button id={"dropdown-item" + item.id} className="dropdown-item" onClick={onSelect}>{item.label}</button></li>
                    ))}
                </ul>
            </div>
        </>
    )
}

SuggestBox.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    floating: PropTypes.bool
}

export default SuggestBox