import React, { useContext, createContext } from "react"
import useAppProvider from './useAppProvider'

const appContext = createContext({});

export const AppProvider = ({ children }) => {
    const app = useAppProvider()
    return (
        <appContext.Provider value={app}>
            {children}
        </appContext.Provider>
    )
}

export const useAppContext = () => {
    return useContext(appContext)
}