import EUR from 'resources/eur.png'
import XOF from 'resources/xof.png'
import USD from 'resources/usd.png'
import BTC from 'resources/btc.png'
import useSummaryOrder from './useOrderSummary'

const OrderSummary = () => {
    const [itemsNumber, totalPrice, shippingCost, totalVAT, totalTTC] = useSummaryOrder()

    return (
        <>
            <div className="d-flex flex-row flex-wrap"><div className="d-flex flex-row">{itemsNumber} article(s)</div><div className="d-flex flex-row-reverse fw-bold w-100">{totalPrice} &euro;</div></div>
            <div className="d-flex flex-row flex-wrap"><div className="d-flex flex-row">Livraison</div><div className="d-flex flex-row-reverse fw-bold w-100">{shippingCost}</div></div>
            <div className="border-top mt-3 pt-3">Total TTC (dont TVA : {totalVAT} &euro;) :</div>
            <div className="ps-4 fw-bold"><img src={EUR} width="20" height="20" alt="EUR" />EUR : {totalTTC["EUR"]} &euro;</div>
            <div className="ps-4"><img src={XOF} width="20" height="20" alt="XOF" />XOF : {totalTTC["XOF"]} F CFA</div>
            <div className="ps-4"><img src={USD} width="20" height="20" alt="USD" />USD : {totalTTC["USD"]} &#36;</div>
            <div className="ps-4"><img src={BTC} width="20" height="20" alt="BTC" />BTC : {totalTTC["BTC"]} bitcoin</div>
        </>
    )
}

export default OrderSummary