import axios from "axios"

const API_URL = "/back/api/"
const API_PRODUCT_URL = API_URL + "product/"

export const getProduct = (path, success, failure) => {
    // const res = {
    //     id: "Mg==", path: "prise-dooxie-complet", label: "Prise de courant complète, Dooxie Legrand", price: 4.57, currency: "EUR" , selledBy: "assiganto.com", deliveredBy: "assiganto.com",
    //     deliveryDate: "livré entre 2 et 5 jours",
    //     deliveryFees: "F CFA 6 500 / kg",
    //     desc: "La prise de courant 2 P + T Dooxie easyréno a été spécialement conçue pour s'intégrer facilement dans les boîte de profondeur 30 mm.<br/>" +
    //     "Ces boîtes se rencontrent quelquefois en rénovation ou sont utilisées dans les cloisons minces.<br/>" +
    //     "Prouesse technique, il restera encore 6 mm entre le fond de la boîte et la prise pour loger le surplus de fils.<br/>" +
    //     "A noter que dans la gamme Dooxie Legrand c'est l'un des deux mécanismes compatible pour l'entraxe 57 mm si on découpe les ailettes à gauche et à droite.<br/>" +
    //     "Prise de courant 2 pôles + terre, 16 A, 250 V, connexion des fils par bornes automatiques, fixation sur la boîte par vis, mais possible par griffes possible avec l'ajout des griffes Dooxie.<br/>",
    //     imgsrc: ["/products/prise-dooxie-complet/short.jpeg", "/products/prise-dooxie/short.jpeg", "/products/plaque-finition-dooxie-simple/short.jpeg"], 
    //     techDesc: [
    //         {header: "Gamme", value: "Dooxie Legrand"},
    //         {header: "Couleur", value: "Blanc"},
    //         {header: "Type de fonction", value: "Prise de courant"},
    //         {header: "Poids", value: "200g"},
    //     ],
    // }
    // success(res)
    // return res
    return axios.get(API_PRODUCT_URL + path).then((response) => {
        success(response.data)
        return response.data;
    }).catch(error => {
        failure()
        throw error
    })
}

export const searchProducts = (query, success, failure) => {
    // const res = [
    //     { id: "Ng==", path: "tam-tam-djembe", imgsrc: "/products/tam-tam-djembe/short.jpeg", label: "Tam tam djembé, Hauteur 37cm, Diamètre 17cm", price: "EUR 49,99" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Nw==", path: "porte-cles-bois-sculpture-tortue", imgsrc: "/products/porte-cles-bois-sculpture-tortue/short.jpeg", label: "Porte-clés bois sculpture  tortue", price: "EUR 5,99" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "OA==", path: "porte-cles-bois-sculpture-tam-tam", imgsrc: "/products/porte-cles-bois-sculpture-tam-tam/short.jpeg", label: "Porte-clés bois sculpture tam tam djembé", price: "EUR 4,69" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "OQ==", path: "porte-cles-bois-sculpture-case", imgsrc: "/products/porte-cles-bois-sculpture-case/short.jpeg", label: "Porte-clés bois sculpture case", price: "EUR 5,89" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    // ]
    // success(res)
    // return res
    return axios.get(API_PRODUCT_URL + "search?q=" + query).then((response) => {
        if (Array.isArray(response.data)) {
            success(response.data)
            return response.data;
        } else {
            failure()
        }
    }).catch(error => {
        failure()
        throw error
    })
}

export const listLinks = (path, success, failure) => {
    // const res = [
    //     { id: "MQ==", path: "prise-dooxie", imgsrc: "/products/prise-dooxie/short.jpeg", label: "Prise de courant, Affleurante, Dooxie Legrand", price: "F CFA 2 500" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Mw==", path: "plaque-finition-dooxie-simple", imgsrc: "/products/plaque-finition-dooxie-simple/short.jpeg", label: "Plaque Dooxie Legrand, 1 poste, Blanc", price: "F CFA 700" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "NA==", path: "plaque-finition-dooxie-double", imgsrc: "/products/plaque-finition-dooxie-double/short.jpeg", label: "Plaque Dooxie Legrand, 2 postes, Blanc", price: "F CFA 2 000" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "NQ==", path: "plaque-finition-dooxie-triple", imgsrc: "/products/plaque-finition-dooxie-triple/short.jpeg", label: "Plaque Dooxie Legrand, 3 postes, Blanc", price: "F CFA 2 700" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    // ]
    // success(res)
    // return res
    return axios.get(API_PRODUCT_URL + path + "/links").then((response) => {
        try {
            const links = Object.values(response.data)
            success(links)
            return links;
        } catch (e) {
            failure()
        }
    }).catch(error => {
        failure()
        throw error
    })
}

export const listStars = (success, failure) => {
    // const res = [
    //     { id: "MQ==", path: "prise-dooxie", imgsrc: "/products/prise-dooxie/short.jpeg", label: "Prise de courant, Affleurante, Dooxie Legrand", price: "F CFA 2 500" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Mg==", path: "prise-dooxie-complet", imgsrc: "/products/prise-dooxie-complet/short.jpeg", label: "Prise de courant complète, Dooxie Legrand", price: "F CFA 3 000" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Mw==", path: "plaque-finition-dooxie-simple", imgsrc: "/products/plaque-finition-dooxie-simple/short.jpeg", label: "Plaque Dooxie Legrand, 1 poste, Blanc", price: "F CFA 700" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "NA==", path: "plaque-finition-dooxie-double", imgsrc: "/products/plaque-finition-dooxie-double/short.jpeg", label: "Plaque Dooxie Legrand, 2 postes, Blanc", price: "F CFA 2 000" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "NQ==", path: "plaque-finition-dooxie-triple", imgsrc: "/products/plaque-finition-dooxie-triple/short.jpeg", label: "Plaque Dooxie Legrand, 3 postes, Blanc", price: "F CFA 2 700" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Ng==", path: "tam-tam-djembe", imgsrc: "/products/tam-tam-djembe/short.jpeg", label: "Tam tam djembé, Hauteur 37cm, Diamètre 17cm", price: "EUR 49,99" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "Nw==", path: "porte-cles-bois-sculpture-tortue", imgsrc: "/products/porte-cles-bois-sculpture-tortue/short.jpeg", label: "Porte-clés bois sculpture  tortue", price: "EUR 5,99" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "OA==", path: "porte-cles-bois-sculpture-tam-tam", imgsrc: "/products/porte-cles-bois-sculpture-tam-tam/short.jpeg", label: "Porte-clés bois sculpture tam tam djembé", price: "EUR 4,69" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    //     { id: "OQ==", path: "porte-cles-bois-sculpture-case", imgsrc: "/products/porte-cles-bois-sculpture-case/short.jpeg", label: "Porte-clés bois sculpture case", price: "EUR 5,89" , selledBy: "assiganto.com", deliveredBy: "assiganto.com" },
    // ]
    // success(res)
    // return res
    return axios.get(API_PRODUCT_URL + "stars").then((response) => {
        try {
            const stars = Object.values(response.data)
            success(stars)
            return stars;
        } catch (e) {
            failure()
        }
    }).catch(error => {
        failure()
        throw error
    })
}

export const listAds = (success, failure) => {
    // const res = [
    //     { key: 14, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B07VCS18BK&asins=B07VCS18BK&linkId=60581af1c10b4a6b6ee20aa0e79c8a82&show_border=true&link_opens_in_new_window=true" },
    //     { key: 15, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B01EZ0X034&asins=B01EZ0X034&linkId=241a12bab236e50e84cb875bcfcd5c09&show_border=true&link_opens_in_new_window=true" },
    //     { key: 1, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B091FS79T8&asins=B091FS79T8&linkId=f26e4fc42b47f943ab24d54a946fbd52&show_border=true&link_opens_in_new_window=true" },
    //     { key: 2, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B07V4CM32T&asins=B07V4CM32T&linkId=8d611e2232fb182591725680acbf6142&show_border=true&link_opens_in_new_window=true" },
    //     { key: 3, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B0967NHLV8&asins=B0967NHLV8&linkId=a80ca810abe569f0029e94280c9fb9b7&show_border=true&link_opens_in_new_window=true" },
    //     { key: 4, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B01N9VBVN1&asins=B01N9VBVN1&linkId=e42e919df0216d894b810825250be0be&show_border=true&link_opens_in_new_window=true" },
    //     { key: 5, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B08BNVVDX5&asins=B08BNVVDX5&linkId=eecbae2584ef584c184176319d7bff2e&show_border=true&link_opens_in_new_window=true" },
    //     { key: 6, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B089ZWQ4YH&asins=B089ZWQ4YH&linkId=a2a8527acb6ed3efa088c957bd7da921&show_border=true&link_opens_in_new_window=true" },
    //     { key: 7, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B08H5JP1Q4&asins=B08H5JP1Q4&linkId=3a50a42d1dc305e464a7c335a45de2ec&show_border=true&link_opens_in_new_window=true" },
    //     { key: 8, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B08351MKJW&asins=B08351MKJW&linkId=a908154b12e84692f80a47e46ad25e6c&show_border=true&link_opens_in_new_window=true" },
    //     { key: 9, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B093PTM84Z&asins=B093PTM84Z&linkId=db5032b7f7a16a35924f6a1ba7aac3d2&show_border=true&link_opens_in_new_window=true" },
    //     { key: 10, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B07V6F43WJ&asins=B07V6F43WJ&linkId=cc083608ef814b749db012fff1be3874&show_border=true&link_opens_in_new_window=true" },
    //     { key: 11, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B07R7Q36P3&asins=B07R7Q36P3&linkId=cd9b73e54a38d16bfda9422d9889afbc&show_border=true&link_opens_in_new_window=true" },
    //     { key: 12, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B07YWYXPC1&asins=B07YWYXPC1&linkId=11accffdf47dee6f6da3f3b45bb0d9de&show_border=true&link_opens_in_new_window=true" },
    //     { key: 13, src: "//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=FR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=assigame227we-21&language=fr_FR&marketplace=amazon&region=FR&placement=B01KXERLDQ&asins=B01KXERLDQ&linkId=669f6bfbf90206b549ba162447cc8672&show_border=true&link_opens_in_new_window=true" },
    // ]
    // success(res)
    // return res
    return axios.get(API_URL + "ads").then((response) => {
        try {
            const ads = Object.values(response.data)
            success(ads)
            return ads;
        } catch (e) {
            failure()
        }
    }).catch(error => {
        failure()
        throw error
    })
}

export const proposals = (data, success, failure) => {
    return axios.post(API_PRODUCT_URL + "proposals", data).then((response) => {
        success(response.data)
        return response.data;
    }).catch(error => {
        failure()
        throw error
    })
}
