import { useState } from "react"
import { useHistory } from "react-router-dom"
import useConstants from 'commons/utils/useConstants'
import AppC from 'components/app'
import { payOrder } from 'commons/adapters/order'

const usePaymentScreen = () => {
    const history = useHistory() 
    const app = AppC.useAppContext()
    const constants = useConstants()
    const amount = app.order?.amount * 1
    const eurAmount = amount.toFixed(constants.currencyFixes["EUR"])
    const xofAmount = (amount * constants.changeRate["EURXOF"]).toFixed(constants.currencyFixes["XOF"])
    const btcAmount = (amount * constants.changeRate["EURBTC"] * constants.cryptoFeesFactor).toFixed(constants.currencyFixes["BTC"])
    const ethAmount = (amount * constants.changeRate["EURETH"] * constants.cryptoFeesFactor).toFixed(constants.currencyFixes["ETH"])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [txEthereum, setTxEthereum] = useState("")
    const [txBitcoin, setTxBitcoin] = useState("")
    const [current, setCurrent] = useState(0)
    const doCollapse = id => {
        setError("")
        setCurrent(id)
    }
    const pay = (payMode, amount, txId) => {
        if ((payMode === "BTC" || payMode === "ETH") && !txId) {
            setError("Numéro de la transaction obligatoire")
            return
        }

        setError("")
        setLoading(true)
        payOrder({
            order: app.order.id,
            amount: amount,
            method: payMode,
            transaction: txId
        }, resp => {
            setLoading(false)
            if (resp && resp["success"] === true) {
                // clear current order and redirect to /summary
                app.clearCurrentOrder()
                history.replace({ pathname: "/summary" })
            } else if (resp && resp["message"]) {
                setError(resp["message"])
            } else {
                setError("Une erreur est survenue lors de la validation de votre paiement!")
            }
        }, () => {
            setLoading(false)
            setError("Une erreur est survenue lors de la validation de votre paiement!")
        })

    }
    const handleCopy = text => {
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        if (window.navigator.platform === 'iPhone') {
          textField.setSelectionRange(0, 99999)
        } else {
          textField.select()
        }
        document.execCommand('copy')
        textField.remove()
    }

    const handleChange = key => (
        e,
    ) => {
        const val = e.target.value
        if (key === "txEthereum") {
            setTxEthereum(val)
        } else if (key === "txBitcoin") {
            setTxBitcoin(val)
        }
    }

    return [current, doCollapse, eurAmount, xofAmount, btcAmount, ethAmount, 
        handleCopy, error, loading, pay, txBitcoin, txEthereum, handleChange]
}

export default usePaymentScreen