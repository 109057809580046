import { Link } from 'react-router-dom'
import logo from 'resources/logo-assigame.svg';
import logoutImg from 'resources/logout.png';
import cartImg from 'resources/mycart.png';
import accountImg from 'resources/account.png';
import ordersImg from 'resources/myorders.png';
import searchIcon from 'resources/search.svg'
import useTopBar from "./useTopBar"

const TopBar = () => {
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
        user,
        cartSize,
        logout
    } = useTopBar()

    return (
        <nav className="navbar navbar-light bg-light justify-content-between">
            <a className="navbar-brand" href="/"><img src={logo} width="30" height="30" className="d-inline-block align-top" alt="assiganto.com" /> assiganto.com</a>
            <form onSubmit={handleSubmit} className="col-sm-5">
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <img src={searchIcon} alt="logo" className="prepend-icon" />
                        </div>
                    </div>
                    <input className="form-control" value={data.searchText || ''} onChange={handleChange('searchText')} placeholder='Rechercher des produits par mots clés...' required />
                    {errors.searchText && <p className="error">{errors.searchText}</p>}
                    <div className="input-group-prepend">
                        <button className="btn btn-primary" type="submit">Rechercher</button>
                    </div>
                </div>
            </form>
            <div className="form-inline">
                { user && 
                    <span className="d-inline-block align-bottom mw-150p text-truncate ms-3 me-2">Bonjour {user?.firstName}</span>
                }
                <Link to="/cart" className="d-inline-block position-relative me-1">
                    <img src={cartImg} width="30" height="30" className="d-inline-block align-top" alt="Panier" title="Panier" /> 
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartSize}</span>
                </Link>
                { user && 
                    <>
                        <Link to="/my-orders" className="d-inline-block ms-3 me-1"><img src={ordersImg} width="30" height="30" className="d-inline-block align-top" alt="Mes commandes" title="Mes commandes" /></Link>
                        <Link to="/account" className="d-inline-block ms-2 me-1"><img src={accountImg} width="30" height="30" className="d-inline-block align-top" alt="Mon compte" title="Mon compte" /></Link>
                        <button onClick={logout} className="btn btn-sm me-1"><img src={logoutImg} width="30" height="30" className="d-inline-block align-top" alt="Se déconnecter" title="Se déconnecter" /></button>
                    </>
                }
                { !user &&  
                    <>
                        <Link to="/login" className="btn btn-sm btn-outline-primary ms-3 me-1">Se connecter</Link>
                        <Link to="/register" className="btn btn-sm btn-outline-secondary me-1">S&apos;inscrire</Link>
                    </>
                }
            </div>
        </nav>
    )
}

export default TopBar