import ProductStar from 'components/mixed/star'
import Loading from 'components/loading'
import usePurchaseScreen from './usePurchaseScreen'

const PurchaseScreen = () => {
    const [loading, orders, pay] = usePurchaseScreen()
    const themes = ["", "text-primary", "text-secondary", "text-info", "text-success"]
    const bgColors = ["", "bg-color-wligth "]

    return (
        <div className="container mw-600p">
            <div className="border-bottom mb-3">
                <h3>Liste de mes commandes</h3>
            </div>
            {(loading &&  <Loading />) || ((orders && orders.length &&
                <div className="border mb-3 justify-content-md-center">
                    <div className="row ms-1 me-1 mb-2 bg-secondary text-light fw-bold">
                        <div className="p-2 col-md-4">Date de la commande (articles)</div>
                        <div className="p-2 col-md-3">Montant TTC</div>
                        <div className="p-2 col-md-3">Statut</div>
                        <div className="p-2 col-md-2">&nbsp;</div>
                    </div><>{orders.map((item, idx) => (
                    <div className={bgColors[idx % 2] + "row ms-1 me-1 pt-2 pb-2"} key={item.id}>
                        <div className="ps-3 col-md-4">{item.date} <span className="fw-bold">({item.items.length})</span></div>
                        <div className="ps-3 col-md-3"><span className="fw-bold">{item.amount} &euro;</span></div>
                        <div className="ps-3 col-md-3"><span className={themes[item.statusCode]}>{item.status}</span></div>
                        <div className="ps-3 col-md-2">{(item.statusCode === 1 && 
                            <button className="btn btn-sm btn-primary d-inline-block" onClick={() => pay(item.id, item.amount)}>Payer</button>) 
                            || <span>&nbsp;</span>}
                        </div>
                    </div>))}</>
                </div>) || (
                <div className="justify-content-md-center mb-2">
                    <span>Aucune commande pass&eacute;e!</span>
                    <ProductStar />
                </div>)
            )}
        </div>
    )
}

export default PurchaseScreen