import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import ProductStar from 'components/mixed/star'
import QuantitySetter from 'components/quantity'
import OrderSummary from 'components/order'
import Breadcrumb from 'components/breadcrumb'
import useCartScreen from './useCartScreen'
import removeCart from 'resources/remove-cart.png'

const CartScreen = () => {
    const [cart, removeFromCart, handleQuantity] = useCartScreen()

    return (
        <div className="mb-3">
            <Breadcrumb step={1} />
            <div className="container d-flex flex-row flex-wrap justify-content-center">{
                (cart && cart.length && 
                    <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                        <div className="p-0">
                            <div className="d-flex flex-row-reverse mb-2"><span className="badge bg-danger text-wrap">Les frais de livraison seront mis à jour en fonction de l'adresse de livraison spécifiée lors de la commande.</span></div>
                            {cart.map(item => (<div key={item.p} className="d-flex flex-column card p-1 mb-2">
                                <div className="d-flex flex-row-reverse p-0">
                                    <button className="btn btn-sm btn-outline-danger d-inline-block border-0" type="button" onClick={() => removeFromCart(item)}>
                                        <img src={removeCart} width="20" height="20" className="d-inline-block align-top" alt="retirer du panier" />
                                    </button>
                                </div>
                                <div key={item.p} className="d-flex flex-row flex-wrap">
                                    <div className="d-flex flex-row flex-wrap w-60"><div className="stretch-label"><Link to={"/product/" + item.pa} className="fs-6 text-start">{parse(item.pl)}</Link></div><div className="fw-bold ms-2">{item.pp.toFixed(2)} &euro;</div></div> 
                                    <QuantitySetter qty={item.q} onQtyChange={val => handleQuantity(item, val)} />
                                    <div><span className="fw-bold badge bg-secondary">EUR {(item.pp * item.q).toFixed(2)} &euro;</span></div>
                                </div>
                            </div>))}
                        </div>
                        <div className="card ms-3 p-2">
                            <OrderSummary />
                            <Link className="btn btn-primary mt-3" to="/order">Commander</Link>
                        </div>
                    </div>
                ) || (
                    <div>
                        <span>Votre panier est vide!</span>
                        <ProductStar />
                    </div>
                )
            }</div>
        </div>
    )
}

export default CartScreen