import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {startsWith} from 'commons/utils/useStrings'
import AppC from 'components/app'

const useProductLayout = () => {
    const app = AppC.useAppContext()
    const [quantity, setQuantity] = useState(1)
    const protocol = window.location.protocol
    const host = window.location.host
    const history = useHistory()

    const add = product => {
        app.addToCart(product, quantity)
    }

    const purchase = product => {
        app.addToCart(product, quantity)
        history.replace({ pathname: "/cart" })
    }

    const handleQuantity = value => {
        setQuantity(value)
    }
    
    const buildLink = link => {
        return (startsWith(link, "https") || startsWith(link, "http")) ? link : protocol + "//" + host + link
    }

    return [buildLink, add, quantity, handleQuantity, purchase]
}

export default useProductLayout