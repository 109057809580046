import axios from "axios"

const API_ORDER_URL = "/back/api/order"

export const saveOrder = (data, success, failure) => {
    return axios
        .post(API_ORDER_URL, data).then((response) => {
            success(response.data)
            return response.data;
        }).catch(error => {
            failure()
            throw error
        })
}

export const payOrder = (data, success, failure) => {
    return axios
        .post(API_ORDER_URL + "/payment", data).then((response) => {
            success(response.data)
            return response.data;
        }).catch(error => {
            failure()
            throw error
        })
}

export const findOrders = (userId, success, failure) => {
    return axios
        .post(API_ORDER_URL + "/list", {userId}).then((response) => {
            success(response.data)
            return response.data;
        }).catch(error => {
            failure()
            throw error
        })
}