import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { findOrders } from 'commons/adapters/order'
import AppC from 'components/app'

const usePurchaseScreen = () => {
    const history = useHistory() 
    const app = AppC.useAppContext()
    const userId = app.user.id
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const pay = (id, amount) => {
        // set to order before redirect to payment page
        app.setCurrentOrder({id, amount});
        history.replace({ pathname: "/payment" })
    }
    useEffect(() => {
        findOrders(userId,  resp => {
                if (resp && resp["success"] && resp["orders"]) {
                    setOrders(resp["orders"])
                }
                setLoading(false)
            }, () => {
                setLoading(false)
            }
        )
    }, [userId])
 
    return [loading, orders, pay]
}

export default usePurchaseScreen