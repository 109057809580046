import AppC from 'components/app'

const useCartScreen = () => {
    const app = AppC.useAppContext()
    const cart = app.cart
    const removeFromCart = item => {
        app.removeFromCart(item)
    }

    const handleQuantity = (item, value) => {
        app.updateCart({...item, q: value})
    }

    return [cart, removeFromCart, handleQuantity]
}

export default useCartScreen