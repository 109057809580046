import { Link } from 'react-router-dom'
import Breadcrumb from 'components/breadcrumb'
import Loading from 'components/loading'
import copy from 'resources/copy.png'
import cbPay from 'resources/cb-visa-mastercard.jpeg'
import flooz from 'resources/flooz.jpeg'
import tmoney from 'resources/tmoney.png'
import bitcoin from 'resources/bitcoin.png'
import ethereum from 'resources/ethereum.png'
import usePaymentScreen from './usePaymentScreen'

const PaymentScreen = () => {
    const [current, doCollapse, eurAmount, xofAmount, btcAmount, ethAmount,
        handleCopy, error, loading, pay, txBitcoin, txEthereum, handleChange] = usePaymentScreen()
    const btcAddress = "3JnNMGAFKVSPpWAQ6LczQDDEzkfezY89UD"
    const ethAddress = "0x8FD9E2558184fBA8ACC343637098C49021dce86E"

    return (
        <div className="mb-3">
            <Breadcrumb step={3} />
            <div className="container d-flex flex-row flex-wrap justify-content-center w-100">
                <div className="card">
                    <div className="mw-450p p-3">
                        Montant total TTC en euros à régler pour votre commande : <span className="fw-bold">{eurAmount} &euro;</span>
                    </div>
                    <div className="mw-450p ps-3 pb-3">
                        Choisissez parmi les moyens de paiement ci-dessous, celui qui vous convient et procédez au réglement.
                    </div>
                    <div className="accordion" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className={"accordion-button" + (current === 1 ? "" : " collapsed")} type="button" onClick={() => doCollapse(1)} data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Paiement par carte bancaire <img src={cbPay} height="30" className="d-inline-block align-top ms-2 me-5" alt="paiement par carte bancaire" />
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className={"accordion-collapse" + (current === 1 ? "" : " collapse")} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Indisponible pour le moment... <Link className="btn btn-sm btn-primary mt-3 d-inline-block align-bottom disabled" to="/payment">Valider</Link></div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className={"accordion-button" + (current === 2 ? "" : " collapsed")} type="button" onClick={() => doCollapse(2)} data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Paiement par t&eacute;l&eacute;phone * <img src={flooz} height="30" className="d-inline-block align-top ms-2 me-1" alt="paiement par flooz" /> 
                                    <img src={tmoney} height="30" className="d-inline-block align-top ms-1 me-5" alt="paiement par T-Money" />
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className={"accordion-collapse" + (current === 2 ? "" : " collapse")} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body p-2">
                                    <div className="mw-450p">
                                        <span className="text-muted fw-light">* Mode de paiement possible uniquement au TOGO.</span><br/>
                                        <div className="fw-bold mt-3 mb-2"><span className="text-decoration-underline">Montant XOF à régler</span> : {xofAmount} F CFA</div>
                                        <span className="badge bg-warning text-dark text-wrap mb-2">Attention, le paiement doit se faire avec le numéro de téléphone spécifié à l'étape "Commande"</span><br/>
                                        Procédez au transfert TMoney ou Flooz selon votre préférence, puis valider le paiement en cliquant sur le bouton "Valider".
                                        <ul>
                                            <li className="mb-2">
                                                <span className="badge bg-secondary">Tmoney : 90 36 51 11<button className="d-inline-block btn btn-sm ms-1 p-0" onClick={() => handleCopy("90365111")}><img src={copy} width="15" height="15" className="d-inline-block align-top" alt="Copier" title="Copier"/></button></span>
                                                {!loading && <button className="btn btn-sm btn-primary d-inline-block ms-1" onClick={() => pay("TMoney", xofAmount, "")}>Valider TMoney</button>}
                                            </li>
                                            <li>
                                                <span className="badge bg-secondary">Flooz&nbsp;&nbsp;&nbsp;&nbsp; : 96 28 51 71<button className="d-inline-block btn btn-sm ms-1 p-0" onClick={() => handleCopy("96285171")}><img src={copy} width="15" height="15" className="d-inline-block align-top" alt="Copier" title="Copier"/></button></span>
                                                {!loading && <button className="btn btn-sm btn-primary d-inline-block ms-1" onClick={() => pay("Flooz", xofAmount, "")}>Valider Flooz</button>}
                                            </li>
                                        </ul>
                                        {loading && <Loading />}
                                        {error && <p className="invalid">{error}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className={"accordion-button" + (current === 3 ? "" : " collapsed")} type="button" onClick={() => doCollapse(3)} data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Paiement en crypto monnaie <img src={bitcoin} width="70" className="d-inline-block align-top ms-2 me-1" alt="paiement par bitcoin" />
                                    <img src={ethereum} width="70" className="d-inline-block align-top ms-1 me-2" alt="paiement par ethereum" />
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className={"accordion-collapse" + (current === 3 ? "" : " collapse")} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body p-0">
                                    <div className="mw-450p">
                                        <div className="badge bg-warning text-dark text-wrap p-2">
                                            Attention, veuillez à transférer le montant BTC vers l'adresse BTC et le montant ETH vers l'adresse ETH!<br/><br/>
                                            <span className="bg-danger text-light text-wrap">NE FAITES JAMAIS DE TRANSFERT BTC VERS L'ADRESSE ETH, NI DE L'ETH VERS L'ADRESSE BTC, SINON VOUS PERDEZ VOTRE ARGENT.</span>
                                        </div>
                                        <div className="ps-1">Proc&eacute;dez au transfert à l'adresse BTC ou ETH puis validez avec le num&eacute;ro de la transaction re&ccedil;u.</div>
                                        <div className="mb-2 ps-1 pe-1">
                                            <div className="fw-bold mt-3 mb-2"><span className="text-decoration-underline">Montant BTC à régler</span> : {btcAmount}</div>
                                            <span className="badge bg-secondary text-wrap ps-1 mb-1">Adresse BTC : <br/>{btcAddress}<button className="d-inline-block btn btn-sm ms-1 p-0" onClick={() => handleCopy(btcAddress)}><img src={copy} width="15" height="15" className="d-inline-block align-top" alt="Copier" title="Copier"/></button></span>
                                            <div className="mb-2 input-group">
                                                <input className="form-control form-control-sm" value={txBitcoin || ''} onChange={handleChange('txBitcoin')} placeholder="Numéro de la transaction" />
                                                <div className="input-group-prepend">
                                                    {!loading && <button className="btn btn-sm btn-primary d-inline-block" onClick={() => pay("BTC", btcAmount, txBitcoin)}>Valider BTC</button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ps-1 pe-1">
                                            <div className="fw-bold mt-3 mb-2"><span className="text-decoration-underline">Montant ETH à régler</span> : {ethAmount}</div>
                                            <span className="badge bg-secondary text-wrap ps-1 mb-1">Adresse ETH : <br/>{ethAddress}<button className="d-inline-block btn btn-sm ms-1 p-0" onClick={() => handleCopy(ethAddress)}><img src={copy} width="15" height="15" className="d-inline-block align-top" alt="Copier" title="Copier"/></button></span>
                                            <div className="mb-2 input-group">
                                                <input className="form-control form-control-sm" value={txEthereum || ''} onChange={handleChange('txEthereum')} placeholder="Numéro de la transaction" />
                                                <div className="input-group-prepend">
                                                    {!loading && <button className="btn btn-sm btn-primary d-inline-block" onClick={() => pay("ETH", ethAmount, txEthereum)}>Valider ETH</button>}
                                                </div>
                                            </div>
                                        </div>
                                        {loading && <Loading />}
                                        {error && <p className="invalid">{error}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentScreen