import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { activate, resentActivation } from 'commons/adapters/auth'

const useActivationScreen = () => {
    const query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(true)
    const [failure, setFailure] = useState("")
    const [success, setSuccess] = useState("")
    const email = query.get("email")
    const key = query.get("key")
    useEffect(() => {
        const error = "Une erreur est survenue lors de l'activation de votre compte!"
        setLoading(true)
        activate(email, key, resp => {
                if (resp && resp["success"] && resp["message"]) {
                    setSuccess(resp["message"])
                } else if (resp && resp["message"]) {
                    setFailure(resp["message"])
                } else {
                    setFailure(error)
                }
                setLoading(false)
            }, () => {
                setFailure(error)
                setLoading(false)
            }
        )
    }, [email, key])
    const resentActivationLink = () => {
        const error = "Une erreur est survenue lors du renvoi du lien d'activation, veuillez nous contacter par mail my.account@assiganto.com!"
        setLoading(true)
        setFailure("")
        setSuccess("")
        resentActivation(email, resp => {
                if (resp && resp["success"] && resp["message"]) {
                    setSuccess(resp["message"])
                } else if (resp && resp["message"]) {
                    setFailure(resp["message"])
                } else {
                    setFailure(error)
                }
                setLoading(false)
            }, () => {
                setFailure(error)
                setLoading(false)
            }
        )
    }

    return [loading, failure, success, resentActivationLink]
}

export default useActivationScreen