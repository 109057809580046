import { useState } from "react"
import { personal } from 'commons/adapters/auth'
import useForm from 'commons/form/useForm'
import useAccountValidations from 'components/account/useAccountValidations'
import AppC from 'components/app'

const usePersonalInfo = () => {
    const app = AppC.useAppContext()
    const [loading, setLoading] = useState(false)
    const [failure, setFailure] = useState("")
    const [success, setSuccess] = useState("")
    const accountValidations = useAccountValidations()
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            ...accountValidations,
        },
        onSubmit: () => {
            const error = "Une erreur est survenue lors de la modification de vos informations personnelles!"
            setFailure("")
            setSuccess("")
            setLoading(true)
            personal({
                    user: app.user.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    address: data.address,
                    country: data.country,
                    zipCodeCity: data.zipCodeCity,
                    localisation: data.localisation,
                    phone: data.phone
                }, resp => {
                    if (resp && resp["success"] && resp["user"]) {
                        app.setUser(prev => ({...prev, ...resp["user"]}))
                        setSuccess("Informations personnelles modifiées!")
                    }else {
                        setFailure(error)
                    }
                    setLoading(false)
                }, () => {
                    setFailure(error)
                    setLoading(false)
                }
            )
        },
        initialValues: {
            firstName: app.user.firstName,
            lastName: app.user.lastName,
            email: app.user.email,
            address: app.user.address,
            country: app.user.country.toString(),
            zipCodeCity: app.user.zipCodeCity,
            localisation: app.user.localisation,
            phone: app.user.phone
        },
    })

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    }
}

export default usePersonalInfo