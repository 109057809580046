import { Link } from "react-router-dom"
import ProductStar from 'components/mixed/star'
import Product from 'components/product'
import useHomeScreen from './useHomeScreen'
import Loading from 'components/loading'
import './HomeScreen.css'

const HomeScreen = () => {
    const {
        loading,
        query,
        searchResults
    } = useHomeScreen()

    return (
        <div>
            <div className="infos">
                {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="..." class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="..." class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="..." class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-center">{
                (loading &&  <Loading />) || (
                    (searchResults && searchResults.length && searchResults.map(item => (
                        <div key={item.id}><Product.ShortLayout product={item}/></div>
                    ))) ||
                    <div className="p-1">{(query && <><span>Aucun produit trouvé correspondant au texte : {query}</span>
                    <br />Vous n'avez pas trouv&eacute; le produit recherch&eacute;? pas de panique, <Link to="/account">cliquez sur ce lien</Link> pour nous proposer des produits que vous avez trouv&eacute; 
                    sur d'autres sites e-commerces. </>) || <span>&nbsp;</span>}</div>
                )
            }</div>
            {!(searchResults && searchResults.length) && <div>
                <ProductStar />
            </div>}
        </div>
    )
  }
  
  export default HomeScreen