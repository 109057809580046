import useProductScreen from './useProductScreen'
import Product from 'components/product'
import Loading from 'components/loading'

const ProductScreen = () => {
    const [loading, productDetails, relatedProducts] = useProductScreen()

    return (
        <>
            { (loading &&  <Loading />) || (productDetails && productDetails.id && <Product.DetailsLayout product={productDetails}/>) }

            <div className="container pt-3 pb-2 fw-bold color-grey">{
                (relatedProducts && relatedProducts.length &&
                    <>
                    Ces articles pourraient vous intéresser :
                    <div className="d-flex flex-row flex-wrap justify-content-center">{relatedProducts.map(item => (
                        <div key={item.id}><Product.ShortLayout product={item}/></div>
                    ))}</div></>
                ) ||
                <div className="p-1">&nbsp;</div>
            }</div>
        </>
    )
}

export default ProductScreen