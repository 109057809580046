import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from 'screens/home'
import LoginScreen from 'screens/login'
import RegisterScreen from 'screens/register'
import ProductScreen from 'screens/product'
import AccountScreen from 'screens/account'
import CartScreen from 'screens/cart'
import OrderScreen from 'screens/order'
import PaymentScreen from 'screens/payment'
import SummaryScreen from 'screens/summary'
import PurchaseScreen from 'screens/purchase'
import ActivationScreen from 'screens/activation'
import ResetPasswordScreen from 'screens/password/reset'
import ChangePasswordScreen from 'screens/password/change'
import AppC from 'components/app'
import TopBar from 'components/header'
import BottomBar from 'components/footer'
import PrivateRoute from 'components/route'
import 'resources/main.css'

function App() {
    return (
        <AppC.AppProvider>
            <Router>
                <div>
                    <TopBar />
                    <div>
                        <Switch>
                            <Route exact path={["/", "/home"]} component={HomeScreen} />
                            <Route exact path="/login" component={LoginScreen} />
                            <Route exact path="/register" component={RegisterScreen} />
                            <Route exact path="/activate" component={ActivationScreen} />
                            <Route exact path="/reset-password" component={ResetPasswordScreen} />
                            <Route exact path="/change-password" component={ChangePasswordScreen} />
                            <Route exact path="/product/:productPath" component={ProductScreen} />
                            <PrivateRoute exact path="/account">
                                <AccountScreen />
                            </PrivateRoute>
                            <PrivateRoute exact path="/cart">
                                <CartScreen />
                            </PrivateRoute>
                            <PrivateRoute exact path="/order">
                                <OrderScreen />
                            </PrivateRoute>
                            <PrivateRoute exact path="/payment">
                                <PaymentScreen />
                            </PrivateRoute>
                            <PrivateRoute exact path="/summary">
                                <SummaryScreen />
                            </PrivateRoute>
                            <PrivateRoute exact path="/my-orders">
                                <PurchaseScreen />
                            </PrivateRoute>
                        </Switch>
                    </div>
                    <BottomBar />
                </div>
            </Router>
        </AppC.AppProvider>
    )
}

export default App
