const QuantitySetter = ({qty, onQtyChange}) => {
    const sanitizeQty = val => val && val > 0 && val < 1000 ? val : 1

    return (
        <div>
            <div className="input-group">
                <div className="input-group-prepend">
                    <button className="btn btn-link btn-sm a-qty" type="button" onClick={() => {onQtyChange(sanitizeQty(qty - 1))}}>-</button>
                </div>
                <input className="form-control-sm qty" min="1" value={qty || 1} onChange={e => onQtyChange(sanitizeQty(parseInt(e.target.value)))} />
                <div className="input-group-prepend">
                    <button className="btn btn-link btn-sm a-qty" type="button" onClick={() => {onQtyChange(sanitizeQty(qty + 1))}}>+</button>
                </div>
            </div>
        </div>
    )
}

export default QuantitySetter