import SuggestBox from 'components/suggest'
import useConstants from 'commons/utils/useConstants'

const AccountInfos = ({forRegistration = true, data, errors, onChange}) => {
    const constants = useConstants()
    const countries = constants.countries
    const invalidClass = "form-control is-invalid"
    const validClass = "form-control"

    return (
        <>
            <div className="row justify-content-md-center">
                <div className="col-md-6 mb-3">
                    <label htmlFor="lastNameId">Nom *</label>
                    <input id="lastNameId" className={(errors.lastName ? invalidClass : validClass) + " text-uppercase"} value={data.lastName || ''} onChange={onChange('lastName')} placeholder='Nom de famille...' />
                    {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                </div>
                <div className="col-md-6 mb-3">
                    <label htmlFor="firstNameId">Pr&eacute;nom(s) *</label>
                    <input id="firstNameId" className={(errors.firstName ? invalidClass : validClass) + " text-capitalize"} value={data.firstName || ''} onChange={onChange('firstName')} placeholder='Prénom...' />
                    {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-12 mb-3">
                    <label htmlFor="addressId">Adresse *</label>
                    <input id="addressId" className={errors.address ? invalidClass : validClass} value={data.address || ''} onChange={onChange('address')} placeholder='Adresse de votre domicile...' />
                    {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-6 mb-3">
                    <label htmlFor="countryId">Pays *</label>
                    <select id="countryId" className={"form-select " + (errors.country ? invalidClass : validClass)} value={data.country || ''} onChange={onChange('country')}>
                        <option value=''>Sélectionnez votre pays</option>
                        {countries.map(country => (<option key={country.id.toString()} value={country.id.toString()}>{country.label}</option>))}
                    </select>
                    {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                </div>
                {(data && data.country && (
                    (data.country === "1" && (
                        <div className="col-md-6 mb-3">
                            <SuggestBox id="zipCodeCityId" className={errors.zipCodeCity ? invalidClass : validClass} value={data.zipCodeCity || ''} label="Code postal - Ville *" 
                                onChange={onChange('zipCodeCity')} placeholder='Commencez par saisir votre code postal ou ville...' />
                            {errors.zipCodeCity && <div className="invalid-feedback">{errors.zipCodeCity}</div>}
                        </div>
                    )) || 
                        <div className="col-md-6 mb-3">
                            <label htmlFor="localisationId">G&eacute;o-localisation</label>
                            <input id="localisationId" className={errors.localisation ? invalidClass : validClass} value={data.localisation || ''} onChange={onChange('localisation')} placeholder='Lien de localisation google maps de votre domicile...' />
                            {errors.localisation && <div className="invalid-feedback">{errors.localisation}</div>}
                        </div>
                )) || 
                    <div className="col-md-6 mb-3">
                        &nbsp;
                    </div>
                }
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-6 mb-3">
                    <label htmlFor="phoneId">Num&eacute;ro de t&eacute;l&eacute;phone *</label>
                    <input id="phoneId" className={errors.phone ? invalidClass : validClass} value={data.phone || ''} onChange={onChange('phone')} placeholder='Numéro de téléphone...' />
                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>
                <div className="col-md-6 mb-3">
                    <label htmlFor="emailId">Adresse mail *</label>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">@</div>
                        </div>
                        {(forRegistration && 
                                <input id="emailId" className={errors.email ? invalidClass : validClass} value={data.email || ''} onChange={onChange('email')} placeholder='Adresse mail valide...' />)
                            ||  <input id="emailId" className={errors.email ? invalidClass : validClass} value={data.email || ''} onChange={onChange('email')} placeholder='Adresse mail valide...' disabled readOnly />
                        }
                    </div>
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
            </div>
        </>
    )
}

export default AccountInfos