import {
    Route,
    Redirect,
} from "react-router-dom"
import AppC from 'components/app'

const PrivateRoute = ({ children, ...rest }) => {
    const app = AppC.useAppContext()

    return (
        <Route
            {...rest}
            render = {({ location }) =>
                app.user ? (
                    children
                ) : (
                    <Redirect to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute