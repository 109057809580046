import Loading from 'components/loading'
import useResetPasswordScreen from './useResetPasswordScreen'

const ResetPasswordScreen = () => {
    const {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    } = useResetPasswordScreen()

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <h2 className="text-center">Demande de r&eacute;initialisation de compte</h2>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="emailId">Adresse mail *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">@</div>
                            </div>
                            <input id="emailId" className={errors.email ? "form-control is-invalid" : "form-control"} value={data.email || ''} onChange={handleChange('email')} placeholder='Votre adresse mail utilisé lors de la création du compte...' />
                        </div>
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        {(loading && <Loading />) || (failure && <p className="invalid">{failure}</p>) || (success && <p className="valid">{success}</p>)}
                        <div className="row justify-content-md-center">
                            <div className="col-md-4 mb-2"><button className="btn btn-primary" type="submit">Réinitialiser</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ResetPasswordScreen