import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import SwiperCore, { Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useProductLayout from './useProductLayout'
import QuantitySetter from 'components/quantity'
import PriceConverter from 'components/price'
import useConstants from 'commons/utils/useConstants'
import cart from 'resources/cart.png'
import 'swiper/swiper-bundle.css'
import './layout.css'

// install Swiper's Controller component
SwiperCore.use([Thumbs])
const DetailsLayout = ({product}) => {
    const dZones = useConstants().deliveryZones
    const [buildLink, add, quantity, handleQuantity, purchase] = useProductLayout()
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const buildSlides = (imgs, clazz) => {
        const slides = []
        imgs.map(item => slides.push(
            <SwiperSlide key={item} >
                <img src={buildLink(item)} alt="product details main" className={clazz} />
            </SwiperSlide>
        ))
        return slides
    }
    const borneInf = d => {
        const r = d * 100;
        return (r - (r % 100)) / 100
    }

    return (
        <div>
            <div className="d-flex flex-row flex-wrap container">
                <div className="p-0 text-center flex-shrink-0">
                    <Swiper id="main" thumbs={{ swiper: thumbsSwiper }}  slidesPerView={1} className="details-card-l">
                        {buildSlides(product.imgsrc, "short-img")}
                    </Swiper>
                    <Swiper id="thumbs" slidesPerView={3} spaceBetween={0} onSwiper={swiper => setThumbsSwiper(swiper)}
                        watchSlidesVisibility watchSlidesProgress className="details-card-l">
                        {buildSlides(product.imgsrc, "thumbnails")}
                    </Swiper>
                </div>
                <div className="p-1 details-card-r flex-grow-1 ms-3">
                    <p className="fs-4 fw-bold p-label">{parse(product.label)}</p>
                    <div className="fs-6 p-desc">
                        <div className="">
                            <div className="d-flex flex-row flex-wrap">
                                <div className="">
                                    Prix : <span className="fs-5 fw-bold">{product.currency} {product.price.toFixed(2)}</span> TTC
                                </div>
                                <QuantitySetter qty={quantity} onQtyChange={handleQuantity} />
                                <button className="btn btn-sm btn-warning d-inline-block align-top" type="button" onClick={() => add(product)}>
                                    <img src={cart} width="20" height="20" className="d-inline-block align-top" alt="assiganto.com" />
                                </button>
                                <button className="btn btn-sm btn-primary d-inline-block align-top ms-1" type="button" onClick={() => purchase(product)}>
                                    Commander
                                </button>
                            </div>
                            <PriceConverter price={product.price} defaultCurrency={product.currency} />
                            <div><span className="text-decoration-underline">Livraison</span> :
                                <ul>{
                                    dZones.map(d => (
                                    <li key={d.code}><span className="fw-bold">{d.label}</span> : à partir de <span className="fw-bold">{borneInf((product.deliveryPriceCoef * d.price).toFixed(2))} &euro;</span>, <span className="fs-7 text-primary fw-bold">{d.dpInfos}</span> et livré <span className="fw-bold">{d.dtInfos}</span></li>
                                    ))
                                }</ul>
                            Livré par : <span className="fw-bold">{product.deliveredBy}</span> ; Vendu par : <span className="fw-bold">{product.selledBy}</span></div>
                        </div>
                    </div>
                    <hr />
                    <div className="fs-6 p-desc">{parse(product.desc)}</div>
                </div>
            </div>
            <div className="d-flex flex-column container p-2">
                {product.techDesc.map(item => (
                    <div key={item.header} className="d-flex flex-wrap flex-row p-2 border">
                        <div className="flex-shrink-0 fw-bold details-card-l">{item.header}</div><div className="flex-grow-1">{parse(item.value)}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

DetailsLayout.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        deliveryPriceCoef: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
        techDesc: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })).isRequired,
        selledBy: PropTypes.string.isRequired,
        deliveredBy: PropTypes.string.isRequired,
        imgsrc: PropTypes.arrayOf(PropTypes.string).isRequired
    })
}

export default DetailsLayout