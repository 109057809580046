import { useHistory, useLocation } from "react-router-dom"

const useRedirect = () => {
    const history = useHistory()
    const location = useLocation()

    const redirectFrom = () => {
        const { from } = location.state || { from: { pathname: "/" } }
        history.replace(from)
    }

    const redirectTo = path => {
        history.replace({ pathname: path ? path : "/" })
    }

    return [
        redirectFrom,
        redirectTo
    ]
}

export default useRedirect