import { useState } from 'react'
import { startsWith } from 'commons/utils/useStrings'

const useSuggestBox = (value, onChange) => {
    const [suggestItems, setSuggestItems] = useState([])
    const [suggestValue, setSuggestValue] = useState(value)
    const [displayBox, setDisplayBox] = useState(false)
    const onSuggest = suggest => {
        const transform = item => ({ id: item.id, label: (item.zip_code || (item.department_code + "00")) + " - " + (item.name || 'INCONNUE') })
        import('resources/cities')
            .then(
                cities => {
                    setSuggestItems(Object.values(cities).filter(
                        elt => startsWith(elt.zip_code, suggest) || startsWith(elt.name, suggest) || (startsWith(transform(elt).label, suggest))
                    ).slice(0, 10).map(transform))
                }
            )
            .catch(err => console.error(err))
    }
    const hideSuggestBox = event => {
        if (!(event.relatedTarget && event.relatedTarget.id && startsWith(event.relatedTarget.id, "dropdown-item"))) {
            setDisplayBox(false)
        }
    }
    const onText = text => {
        clearSelectedValue()
        if (text) {
            onSuggest(text, setSuggestItems)
            setDisplayBox(true)
            setSuggestValue(text)
        } else {
            setDisplayBox(false)
            setSuggestValue(null)
        }
    }
    const onSelect = e => {
        e.preventDefault()
        const val = e.target.innerText
        setSuggestValue(val)
        setDisplayBox(false)
        // dispatch the event with selected item to the form control
        onChange(e)
    }
    const clearSelectedValue = () => {
        const eventVal = { writable: false, value: "" }
        const event = new Event('change', { bubbles: true })
        Object.defineProperty(event, 'target', eventVal)
        Object.defineProperty(event, 'currentTarget', eventVal)
        // dispatch the event with empty text to the form control
        onChange(event)
    }

    return [suggestItems, suggestValue, displayBox, hideSuggestBox, onText, onSelect]
}

export default useSuggestBox