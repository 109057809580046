import './BottomBar.css'
import wLogoWeaf from 'resources/w-logo.png';

const BottomBar = () => {
    return (
        <div className="weaf-footer-plus">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center pt-3 pb-2 fw-bold"><span>&copy; 2021 assiganto by WEAF</span></div>
                    <div className="clearfix"></div>
                    <div className="col-12"><hr/>
                        <p className="footer-plus-img"><img src={wLogoWeaf} className="w-logo" alt="Weaf logo" /></p>
                        <p className="footer-plus-text">Société par actions simplifiée unipersonnelle, immatriculée auprès du RCS de La Rochelle sous le numéro 879 020 857, au capital de 1 000€, dont le siège social est situé au 21 Rue des papillons, 17170 - Saint jean de liversay. 
                        WEAF est une société spécialisée dans l'analyse, la conception et le développement de sites internet et d'applications web. Elle intervient également dans le domaine de conseil, assistance et audit en informatique auprès des grandes entreprises et PME.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomBar