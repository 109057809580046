import { useEffect, useState } from 'react'
import { listStars } from 'commons/adapters/product'

const useProductStar = () => {
    const [loading, setLoading] = useState(false);
    const [productStarList, setProductStarList] = useState([])

    useEffect(() => {
        setLoading(true)
        listStars(res => {
            setProductStarList(res)
            setLoading(false)
        }, () => {setLoading(false)})
    }, [])

    return [loading, productStarList]
}

export default useProductStar