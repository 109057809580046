import { Link } from "react-router-dom"
import Loading from 'components/loading'
import useLoginScreen from './useLoginScreen'

const LoginScreen = () => {
    const {
        data,
        handleChange,
        handleSubmit,
        errors,
        loginError,
        initLoginError,
        loading
    } = useLoginScreen()
    const invalidClass = "form-control is-invalid"
    const validClass = "form-control"

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <h2 className="text-center">Connexion</h2>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="emailId">Adresse mail *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">@</div>
                            </div>
                            <input id="emailId" className={errors.email ? invalidClass : validClass} value={data.email || ''} onChange={handleChange('email', initLoginError)} placeholder='votre adresse mail lors de votre enregistrement...' />
                        </div>
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="pwdId">Mot de passe *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">**</div>
                            </div>
                            <input id="pwdId" className={errors.password ? invalidClass : validClass} value={data.password || ''} onChange={handleChange('password', initLoginError)} placeholder='votre mot de passe...' type='password' />
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <Link to={"/reset-password?email=" + (data.email || '')}>Mot de passe oubli&eacute;</Link>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        {(loading && <Loading />) || (loginError && <p className="invalid">{loginError}</p>)}
                        <div className="row justify-content-md-center">
                            <div className="col-md-4 mb-2"><button className="btn btn-primary" type="submit">Connecter</button></div>
                            <div className="col-md-4 mb-2"><Link to="/register" className="btn btn-outline-secondary">S&apos;inscrire</Link></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LoginScreen