import useProductStar from './useProductStar'
import Product from 'components/product'
import Loading from 'components/loading'

const ProductStar = () => {
    const [loading, productStarList] = useProductStar()

    return (
        <div className="d-flex flex-row flex-wrap justify-content-center">{
            (loading && <Loading />) || (
                (productStarList && productStarList.length && productStarList.map(item => (
                    <div key={item.id}><Product.ShortLayout product={item}/></div>
                ))) ||
                <div className="p-1">&nbsp;</div>
            )
        }</div>
    )
}

export default ProductStar