import parse from 'html-react-parser'
import Loading from 'components/loading'
import useProductProposal from './useProductProposal'

const ProductProposal = () => {
    const {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    } = useProductProposal()
    const invalidClass = "form-control is-invalid"
    const validClass = "form-control"

    return (
        <div className="p-2 mb-3">
            <div className="border-bottom mb-3">
                <h3>Proposition de(s) produit(s) à commander</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url1Id" className={errors.url1 ? invalidClass : validClass} value={data.url1 || ''} onChange={handleChange('url1')} />
                        <label htmlFor="url1Id">Url produit 1 *</label>
                        {errors.url1 && <div className="invalid-feedback">{errors.url1}</div>}
                    </div>
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url2Id" className={errors.url2 ? invalidClass : validClass} value={data.url2 || ''} onChange={handleChange('url2')} />
                        <label htmlFor="url2Id">Url produit 2</label>
                        {errors.url2 && <div className="invalid-feedback">{errors.url2}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url3Id" className={errors.url3 ? invalidClass : validClass} value={data.url3 || ''} onChange={handleChange('url3')} />
                        <label htmlFor="url3Id">Url produit 3</label>
                        {errors.url3 && <div className="invalid-feedback">{errors.url3}</div>}
                    </div>
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url4Id" className={errors.url4 ? invalidClass : validClass} value={data.url4 || ''} onChange={handleChange('url4')} />
                        <label htmlFor="url4Id">Url produit 4</label>
                        {errors.url4 && <div className="invalid-feedback">{errors.url4}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url5Id" className={errors.url5 ? invalidClass : validClass} value={data.url5 || ''} onChange={handleChange('url5')} />
                        <label htmlFor="url5Id">Url produit 5</label>
                        {errors.url5 && <div className="invalid-feedback">{errors.url5}</div>}
                    </div>
                    <div className="col-md-6 mb-3 form-floating">
                        <input id="url6Id" className={errors.url6 ? invalidClass : validClass} value={data.url6 || ''} onChange={handleChange('url6')} />
                        <label htmlFor="url6Id">Url produit 6</label>
                        {errors.url6 && <div className="invalid-feedback">{errors.url6}</div>}
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    {(failure && <p className="invalid">{failure}</p>) || (success && <p className="valid">{parse(success)}</p>)}
                    <div className="col-md-4">
                        {(loading && <Loading />) || <button className="col-md-12 btn btn-primary" type="submit">Proposer</button>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ProductProposal