import Breadcrumb from 'components/breadcrumb'

const SummaryScreen = () => {

    return (
        <div className="mb-3">
            <Breadcrumb step={4} />
            <div className="container d-flex flex-row flex-wrap justify-content-center w-100">
                <p>Votre commande est enregistr&eacute;e avec succ&egrave;s.<br/>Votre commande vous sera livrée dans de plus bref délai!<br/>Toute l'&eacute;quipe assiganto.com vous remercie de votre confiance!</p>
            </div>
        </div>
    )
}

export default SummaryScreen