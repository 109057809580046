import { useState } from 'react'
import AppC from 'components/app'
import useForm from 'commons/form/useForm'
import useRedirect from 'commons/utils/useRedirect'

const useLoginScreen = () => {
    const app = AppC.useAppContext()
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [redirectFrom] = useRedirect()
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            email: {
                required: {
                    value: true,
                    message: "L'adresse mail est requise!",
                },
            },
            password: {
                required: {
                    value: true,
                    message: 'Le mot de passe est requis!',
                },
            },
        },
        onSubmit: () => {
            setLoading(true)
            app.signin(data.email, data.password, () => {
                setLoading(false)
                redirectFrom()
            }, error => {
                setLoading(false)
                setLoginError(error)
            })
        },
        initialValues: {
            email: '',
            password: '',
        },
    })
    const initLoginError = value => {
        setLoginError("")
        return value
    }

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        loginError,
        initLoginError,
        loading
    }

}

export default useLoginScreen