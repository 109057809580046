import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { resetPassword } from 'commons/adapters/auth'
import useForm from 'commons/form/useForm'

const useResetPasswordScreen = () => {
    const query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(false)
    const [failure, setFailure] = useState("")
    const [success, setSuccess] = useState("")
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            email: {
                required: {
                    value: true,
                    message: "L'adresse mail est requise!",
                },
            },
        },
        onSubmit: () => {
            const error = "Une erreur est survenue lors de la réinitialisation de votre mot de passe!"
            setLoading(true)
            resetPassword(data.email, resp => {
                    if (resp && resp["success"] && resp["message"]) {
                        setSuccess(resp["message"])
                    } else if (resp && resp["message"]) {
                        setFailure(resp["message"])
                    } else {
                        setFailure(error)
                    }
                    setLoading(false)
                }, () => {
                    setFailure(error)
                    setLoading(false)
                }
            )
        },
        initialValues: {
            email: query.get("email"),
        },
    })

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    }
}

export default useResetPasswordScreen