import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProduct, listLinks } from 'commons/adapters/product'

const useProductScreen = () => {
    const { productPath } = useParams()
    const [productDetails, setProductDetails] = useState({})
    const [relatedProducts, setRelatedProducts] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        getProduct(productPath, res => {
            setProductDetails(res)
            setLoading(false)
        }, () => {setLoading(false)})
        listLinks(productPath, res => setRelatedProducts(res), () => {})
    }, [productPath])

    return [loading, productDetails, relatedProducts]
}

export default useProductScreen