import { useState } from 'react'
import useLocalStorage from './useLocalStorage'
import { login, logout } from 'commons/adapters/auth'

const useAppProvider = () => {
    const [user, setUser] = useLocalStorage('user', null)
    const [order, setOrder] = useLocalStorage('order', null)
    const [searchText, setSearchText] = useState('')
    const [deliveryZone, setDeliveryZone] = useState('')
    const [cart, setCart] = useLocalStorage('cart', [])
    const buildP = (product, quantity) => {
      return {
        p: product.id,
        pa: product.path,
        pl: product.label,
        pp: product.price,
        pdc: product.deliveryPriceCoef,
        q: quantity
      }
    }
    const itemProductEqual = (item, product) => {
      return item.p === product.id && item.pa === product.path
    }
    const itemsEqual = (item1, item2) => {
      return item1.p === item2.p && item1.pa === item2.pa
    }

    const signin = (username, password, successCb, failureCb) => {
      return login(username, password, authUser => {
        setUser(authUser)
        successCb()
      }, failureCb)
    }
  
    const signout = cb => {
      return logout(() => {
        setUser(null)
        cb()
      })
    }

    const search = query => {
      setSearchText(query)
    }

    const addToCart = (product, quantity) => {
      const oldElt = cart.find(elt => itemProductEqual(elt, product))
      setCart(prev => oldElt ? [...prev.filter(item => !itemProductEqual(item, product)), buildP(product, quantity + (oldElt.q || 0))]
                             : [...prev, buildP(product, quantity)])
    }

    const updateCart = newItem => {
      setCart(prev => [...prev.filter(item => !itemsEqual(item, newItem)), newItem])
    }

    const removeFromCart = itemToRemove => {
      setCart(prev => [...prev.filter(item => !itemsEqual(item, itemToRemove))])
    }

    const setCurrentOrder = o => {
      setOrder(o)
      setCart([])
    }

    const clearCurrentOrder = () => {
      setOrder(null)
    }
  
    return {
      user,
      setUser,
      searchText,
      cart,
      order,
      signin,
      signout,
      search,
      addToCart,
      updateCart,
      removeFromCart,
      deliveryZone,
      setDeliveryZone,
      setCurrentOrder,
      clearCurrentOrder
    }
}

export default useAppProvider