import { useHistory } from "react-router-dom"
import { useState, useEffect } from 'react'
import { saveOrder } from 'commons/adapters/order'
import useConstants from 'commons/utils/useConstants'
import AppC from 'components/app'
import useForm from 'commons/form/useForm'

const useOrderScreen = () => {
    const history = useHistory() 
    const app = AppC.useAppContext()
    const countries = useConstants().countries
    const [loading, setLoading] = useState(false)
    const [savingError, setSavingError] = useState("")
    const [billingAddressDifferent, setBillingAddressDifferent] = useState(false)
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            fullName: {
                required: {
                    value: true,
                    message: "Le nom et prénom(s) sont requis!",
                },
                pattern: {
                    value: /^[A-Za-z '-]*$/,
                    message: "Le nom et prénom(s) ne doivent contenir que de caractères alphabétiques, le trait d'union, l'espacement et l'apostrophe!",
                },
            },
            phone: {
                required: {
                    value: true,
                    message: "Le numéro de téléphone est requis!",
                },
                pattern: {
                    value: /^[0-9]{8,}$/,
                    message: "Le numéro de téléphone doit contenir au moins 8 chiffres!",
                },
            },
            address: {
                required: {
                    value: true,
                    message: "L'adresse de domicile est requise!",
                }
            },
            country: {
                required: {
                    value: true,
                    message: "Le pays est requis!",
                }
            },
            zipCodeCity: {
                required: {
                    isConditional: data => data.country && data.country === "1",
                    message: "Sélectionnez votre code postal - ville dans les suggestions proposées...",
                },
            },
            localisation: {
                pattern: {
                    value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                    message: "Le lien de géolocalisation est invalide!",
                },
            },
            billingAddress: {
                required: {
                    isConditional: data => data.billingAddressDifferent,
                    message: "L'adresse de facturation est requise si elle est différente de l'adresse de livraison!",
                },
            },
        },
        onSubmit: () => {
            const countryLabel = countries.filter(item => item.id.toString() === data.country)[0].label
            setSavingError("")
            setLoading(true)
            saveOrder({
                items: app.cart.map(item => ({id: item.p, path: item.pa, qty: item.q})),
                user: app.user.id,
                fullName: data.fullName,
                email: app.user.email,
                phone: data.phone,
                localisation: data.localisation,
                shippingAddress: data.fullName + ' ' + data.address + ' ' + data.zipCodeCity + ' ' + countryLabel,
                billingAddress: data.billingAddress,
                country: data.country,
                departmentCode: data.zipCodeCity ? data.zipCodeCity.substring(0, 2) : "-1",
            }, resp => {
                setLoading(false)
                if (resp && resp["success"] === true && resp["order"]) {
                    // update storage with saved order id (resp["order"]) and redirect to /payment
                    app.setCurrentOrder(resp["order"]);
                    history.replace({ pathname: "/payment" })
                } else if (resp && resp["message"]) {
                    setSavingError(resp["message"])
                } else {
                    setSavingError("Une erreur est survenue lors de l'enregistrement de votre commande!")
                }
            }, () => {
                setLoading(false)
                setSavingError("Une erreur est survenue lors de l'enregistrement de votre commande!")
            })
        },
        initialValues: {
            fullName: app.user.firstName + ' ' + app.user.lastName,
            phone: app.user.phone,
            address: app.user.address,
            country: app.user.country.toString(),
            zipCodeCity: app.user.zipCodeCity,
            localisation: app.user.localisation,
            billingAddress: '',
            billingAddressDifferent: false
        },
    })
    const formControlChange = (key, sanitizeFn) => (
        e,
    ) => {
        setSavingError("")
        handleChange(key, sanitizeFn).call(this, e)
    }
    const setIsBillingAddress = checked => {
        data.billingAddressDifferent = checked
        setBillingAddressDifferent(checked)
        setSavingError("")
    }
    const departmentCode = parseInt(data.zipCodeCity ? data.zipCodeCity.substring(0, 2) : "-1")
    const countryId = parseInt(data.country)

    useEffect(() => {
        app.setDeliveryZone(countryId > 1 || departmentCode > 95 ? 'B' : 'A')
    }, [app, countryId, departmentCode])

    return {
        data,
        formControlChange,
        handleSubmit,
        errors,
        savingError,
        billingAddressDifferent,
        setIsBillingAddress,
        countries,
        loading
    }

}

export default useOrderScreen