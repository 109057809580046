import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { resetPassword } from 'commons/adapters/auth'
import AppC from 'components/app'

const useAccountScreen = () => {
    const query = new URLSearchParams(useLocation().search)
    const app = AppC.useAppContext()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState("")
    const [failure, setFailure] = useState("")
    const [action, setAction] = useState("")
    const a = query.get("a")
    const reinitPwd = () => {
        const error = "Une erreur est survenue lors de la réinitialisation de votre mot de passe!"
        setLoading(true)
        resetPassword(app.user.email, resp => {
                if (resp && resp["success"] && resp["message"]) {
                    setSuccess(resp["message"])
                } else if (resp && resp["message"]) {
                    setFailure(resp["message"])
                } else {
                    setFailure(error)
                }
                setLoading(false)
            }, () => {
                setFailure(error)
                setLoading(false)
            }
        )

    }
    useEffect(() => {
        setLoading(false)
        setSuccess("")
        setFailure("")
        setAction(a ? a : "pp")
    }, [a])

    return [action, loading, success, failure, reinitPwd]
}

export default useAccountScreen