import { Link } from 'react-router-dom'
import useBreadcrumb from './useBreadcrumb'

const Breadcrumb = ({step}) => {
    const steps = useBreadcrumb(step)

    return (
        <div className="container border-bottom mt-3 mb-2 d-flex flex-row">{steps.map(
            item => (
                <div key={item.id} className="me-1">{((item.isTitle && <span className={item.isCurrent ? "badge bg-secondary" : "text-muted"}>{item.title}</span>) 
                    || <Link to={item.path}>{item.title}</Link>)}{item.id !== 4 && <span className="ms-1">|</span>}</div>
            )
        )}</div>
    )
}

export default Breadcrumb