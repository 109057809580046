import { useEffect, useState } from 'react'
import AppC from 'components/app'
import { searchProducts } from 'commons/adapters/product'

const useHomeScreen = () => {
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const app = AppC.useAppContext()

    useEffect(() => {
        if (app.searchText) {
            setLoading(true)
            setQuery(app.searchText)
            searchProducts(app.searchText, res => {
                setSearchResults(res)
                setLoading(false)
            }, () => setLoading(false))
        }
    }, [app.searchText])

    return {
        loading,
        query,
        searchResults
    }
}
  
export default useHomeScreen