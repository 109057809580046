import axios from "axios"

const API_URL = "/back/api"

export const register = (data, success, failure) => {
    // const res = {...data}
    // success(res)
    // return res
    return axios.post(API_URL + "/account", {
        ...data
    }).then((response) => {
        success(response.data)
        return response.data;
    }).catch(error => {
        failure()
        throw error
    })
}

export const personal = (data, success, failure) => {
    return axios.post(API_URL + "/personal", data).then((response) => {
        success(response.data)
        return response.data;
    }).catch(error => {
        failure()
        throw error
    })
}

export const login = (username, password, success, failure) => {
    // const res = {fullName: "Toto Titi"}
    // success(res)
    // return res
    const msg = "Une erreur est survenue lors de l'authentification!"
    return axios
        .post(API_URL + "/login", {
            username,
            password,
        }).then((response) => {
            const resp = response.data
            if (resp && resp["success"]) { // && resp["access_token"]
                success(resp["user"])
            } else if (resp && resp["message"]) {
                failure(resp["message"])
            } else {
                failure(msg)
            }
            return response.data;
        }).catch(error => {
            failure(msg)
            throw error
        })
}

export const logout = cb => {
    // nothing to do here
    cb()
    return
}

export const activate = (email, key, success, failure) => {
    return axios
        .post(API_URL + "/validate-email", {
            email,
            key
        }).then((response) => {
            success(response.data)
            return response.data
        }).catch(error => {
            failure()
            throw error
        })
}

export const resentActivation = (email, success, failure) => {
    return axios
        .post(API_URL + "/resent-validate-email", {
            email
        }).then((response) => {
            success(response.data)
            return response.data
        }).catch(error => {
            failure()
            throw error
        })
}

export const resetPassword = (email, success, failure) => {
    return axios
        .post(API_URL + "/reset-password", {
            email
        }).then((response) => {
            success(response.data)
            return response.data
        }).catch(error => {
            failure()
            throw error
        })
}

export const changePassword = (email, key, password, success, failure) => {
    return axios
        .post(API_URL + "/change-password", {
            email,
            key,
            password
        }).then((response) => {
            success(response.data)
            return response.data
        }).catch(error => {
            failure()
            throw error
        })
}