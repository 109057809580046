import AppC from 'components/app'

const useBreadcrumb = (step) => {
    const app = AppC.useAppContext()
    const cartExists = app.cart && app.cart.length > 0
    const orderExists = app.order !== null
    const steps = [
        {
            id: 1,
            title: "Panier",
            path: "/cart",
            isTitle: step === 1 || !cartExists,
            isCurrent: step === 1
        },
        {
            id: 2,
            title: "Commande",
            path: "/order",
            isTitle: step === 2 || !cartExists,
            isCurrent: step === 2
        },
        {
            id: 3,
            title: "Paiement",
            path: "/payment",
            isTitle: step === 3 || !orderExists,
            isCurrent: step === 3
        },
        {
            id: 4,
            title: "Récapitulatif",
            path: "/summary",
            isTitle: step <= 4,
            isCurrent: step === 4
        }
    ]

    return steps
}

export default useBreadcrumb