import Loading from 'components/loading'
import useChangePasswordScreen from './useChangePasswordScreen'

const ChangePasswordScreen = () => {
    const {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    } = useChangePasswordScreen()

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <h2 className="text-center">Modification du mot de passe</h2>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="pwdId">Nouveau mot de passe *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">**</div>
                            </div>
                            <input id="pwdId" className={errors.password ? "form-control is-invalid" : "form-control"} value={data.password || ''} onChange={handleChange('password')} placeholder='Nouveau mot de passe...' type='password' />
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="pwdConfirmId">Confirmation du mot de passe *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">**</div>
                            </div>
                            <input id="pwdConfirmId" className={errors.passwordConfirm ? "form-control is-invalid" : "form-control"} value={data.passwordConfirm || ''} onChange={handleChange('passwordConfirm')} placeholder='Confirmation du mot de passe...' type='password' />
                        </div>
                        {errors.passwordConfirm && <div className="invalid-feedback">{errors.passwordConfirm}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mb-3">
                        {(loading && <Loading />) || (failure && <p className="invalid">{failure}</p>) || (success && <p className="valid">{success}</p>)}
                        <div className="row justify-content-md-center">
                            <div className="col-md-4 mb-2"><button className="btn btn-primary" type="submit">Valider</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordScreen