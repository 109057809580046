import AccountInfos from 'components/account'
import Loading from 'components/loading'
import useRegisterScreen from './useRegisterScreen'

const RegisterScreen = () => {
    const {
        data,
        formControlChange,
        handleSubmit,
        errors,
        registerError,
        registerSuccess,
        loading,
    } = useRegisterScreen()
    const invalidClass = "form-control is-invalid"
    const validClass = "form-control"

    return (
        <div className="container col-md-6">
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <h2 className="text-center">Inscription</h2>
                </div>
                <AccountInfos data={data} errors={errors} onChange={formControlChange} />
                <div className="row justify-content-md-center">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="pwdId">Mot de passe *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">**</div>
                            </div>
                            <input id="pwdId" className={errors.password ? invalidClass : validClass} value={data.password || ''} onChange={formControlChange('password')} placeholder='Mot de passe...' type='password' />
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="pwdConfirmId">Confirmation du mot de passe *</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">**</div>
                            </div>
                            <input id="pwdConfirmId" className={errors.passwordConfirm ? invalidClass : validClass} value={data.passwordConfirm || ''} onChange={formControlChange('passwordConfirm')} placeholder='Confirmation du mot de passe...' type='password' />
                        </div>
                        {errors.passwordConfirm && <div className="invalid-feedback">{errors.passwordConfirm}</div>}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 mb-3">
                        {registerError && <p className="invalid">{registerError}</p>}
                        {registerSuccess && <p className="valid">{registerSuccess}</p>}
                        <div className="row justify-content-md-center">
                            <div className="col-md-4 mb-2">
                                {(loading && <Loading />) ||
                                    <button className="col-md-12 btn btn-primary" type="submit">Enregistrer</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RegisterScreen