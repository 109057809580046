import Loading from 'components/loading'
import useActivationScreen from './useActivationScreen'

const ActivationScreen = () => {
    const [loading, failure, success, resentActivationLink] = useActivationScreen()

    return (
        <div className="container">
            <div className="row justify-content-md-center">
                <h2 className="text-center">Activation du compte nouvellement créé</h2>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-4 mb-2">
                    {(loading && <><p className="text-center">Veuillez patienter...</p><Loading /></>) 
                        || (failure && <p className="invalid text-center">{failure} <br/> <br/>
                                <button className="btn btn-warning" type="button" onClick={() => resentActivationLink()}>Renvoyer le lien d'activation</button></p>) 
                        || (success && <p className="valid text-center">{success}</p>)}
                </div>
            </div>
        </div>
    )
}

export default ActivationScreen