import { useState } from 'react'
import useConstants from 'commons/utils/useConstants'

const usePriceConverter = price => {
    const [currentPrice, setCurrentPrice] = useState((price * 656).toFixed(0))
    const [currentCurrency, setCurrentCurrency] = useState("XOF")
    const constants = useConstants()
    const currencies = constants.changeRate
    const fixes = constants.currencyFixes

    const convert = (p, from, to) => {
        const converted = p * currencies[from + to]
        setCurrentPrice(converted.toFixed(fixes[to]))
        setCurrentCurrency(to)
    }

    return [currentPrice, currentCurrency, convert]
}

export default usePriceConverter