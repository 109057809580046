import { useState } from 'react'
import { register } from 'commons/adapters/auth'
import useForm from 'commons/form/useForm'
import useAccountValidations from 'components/account/useAccountValidations'

const useRegisterScreen = () => {
    const [loading, setLoading] = useState(false)
    const [registerError, setRegisterError] = useState("")
    const [registerSuccess, setRegisterSuccess] = useState("")
    const accountValidations = useAccountValidations()
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            ...accountValidations,
            password: {
                required: {
                    value: true,
                    message: 'Le mot de passe est requis!',
                },
                pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[-()a-zA-Z0-9@!:%._+~#=]{8,}$/,
                    message: "Le mot de passe doit contenir au moins: 8 caractères, un chiffre, un caractère majuscule et un caractère minuscule!",
                },
            },
            passwordConfirm: {
                required: {
                    value: true,
                    message: 'La confirmation du mot de passe est requise!',
                },
                custom: {
                    isValid: (value, data) => value && data && data.password && data.password === value,
                    message: "La confirmation du mot de passe est incorrecte!",
                },
            },
        },
        onSubmit: () => {
            setRegisterError("")
            setRegisterSuccess("")
            setLoading(true)
            register({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                address: data.address,
                country: data.country,
                zipCodeCity: data.zipCodeCity,
                localisation: data.localisation,
                phone: data.phone,
                password: data.password
            }, resp => {
                setLoading(false)
                if (resp && resp["success"] && resp["message"]) {
                    setRegisterSuccess(resp["message"])
                } else if (resp && resp["message"]) {
                    setRegisterError(resp["message"])
                } else {
                    setRegisterError("Une erreur est survenue lors de l'enregistrement!")
                }
            }, () => {
                setLoading(false)
                setRegisterError("Une erreur est survenue lors de l'enregistrement!")
            })
        },
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            country: '',
            zipCodeCity: '',
            localisation: '',
            phone: '',
            password: ''
        },
    })
    const formControlChange = (key, sanitizeFn) => (
        e,
    ) => {
        setRegisterError("")
        setRegisterSuccess("")
        handleChange(key, sanitizeFn).call(this, e)
    }

    return {
        data,
        formControlChange,
        handleSubmit,
        errors,
        registerError,
        registerSuccess,
        loading
    }

}

export default useRegisterScreen