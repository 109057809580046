const useConstants = () => {
    const constants = {
        cryptoFeesFactor: 2,
        changeRate: {
            EURXOF: 656,
            EURUSD: 1.19,
            EURBTC: 1/40000,
            EURETH: 1/2500
        },
        currencyFixes: {
            EUR: 2,
            XOF: 0,
            USD: 2,
            BTC: 6,
            ETH: 6
        },
        countries: [
            {id: 1, label: "FRANCE"},
            {id: 2, label: "TOGO"},
        ],
        deliveryZones: [
            {code: "A", price: 3, dLimit: 50, dLimitCoef: 0.0, label: "France métropolitaine", dpInfos: "GRATUIT dès 50 € d'achat", dtInfos: "entre 3 et 5 jours"},
            {code: "B", price: 15, dLimit: 500, dLimitCoef: 0.9, label: "DOM-TOM et International", dpInfos: "-10% dès 500 € d'achat", dtInfos: "entre 8 et 15 jours"},
        ]
    }

    return constants

}

export default useConstants