import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import useForm from 'commons/form/useForm'
import AppC from 'components/app'

const useTopBar = () => {
    const [cartSize, setCartSize] = useState(0)
    const history = useHistory()
    const app = AppC.useAppContext()
    const user = app.user
    
    useEffect(() => {
        if (app.cart) {
            setCartSize(app.cart.reduce( (a,b) => a + (b["q"] || 0), 0))
        }
    }, [app.cart])

    const logout = () => {
        app.signout(() => history.push("/"))
    }

    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
        searchText: {
            required: {
                value: true,
                message: 'Le champ de recherche de produits est requis!',
            },
        },
        },
        onSubmit: () => {
            app.search(data.searchText)
            history.push("/")
        },
        initialValues: {
            searchText: '',
        },
    })

    return {
        handleSubmit,
        handleChange,
        data,
        errors,
        user,
        cartSize,
        logout
    }
}

export default useTopBar