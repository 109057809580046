import { Link } from 'react-router-dom'
import ProductProposal from 'components/proposal'
import PersonalInfo from 'components/personal'
import Loading from 'components/loading'
import useAccountScreen from './useAccountScreen'

const AccountScreen = () => {
    const [action, loading, success, failure, reinitPwd] = useAccountScreen()
    const isProposal = action === "pp"
    const isUpdate = action === "md"

    return (
        <div className="m-0 d-flex flex-row flex-wrap justify-content-center">
            <div className="card ms-3 me-3 p-2 border-top-0">
                <div className="mt-3"><Link className={"text-decoration-none" + (isProposal ? " link-secondary" : "")} to="/account?a=pp">Proposer des produits</Link></div>
                <div className="mt-3"><Link className={"text-decoration-none" + (isUpdate ? " link-secondary" : "")} to="/account?a=md">Modifier mes donn&eacute;es</Link></div>
                <div className="mt-3 mb-2 border-top"><button className="btn btn-primary mt-3" onClick={() => reinitPwd()}>R&eacute;initialiser le mot de passe</button></div>
            </div>
            <div className="account-content ms-1 pt-2">
                {(loading && <Loading />) || (failure && <p className="invalid">{failure}</p>) || (success && <p className="valid">{success}</p>)}
                {isProposal && <ProductProposal />}
                {isUpdate && <PersonalInfo />}
            </div>
        </div>
    )
}

export default AccountScreen