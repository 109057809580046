import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Link } from "react-router-dom"
import useProductLayout from './useProductLayout'
import './layout.css'

const ShortLayout = ({product}) => {
    const [buildLink, add] = useProductLayout()
    const detailsLink = "/product/" + product.path

    return (
        <div className="d-flex flex-column mb-3 flex-wrap card m-1 w-300-px">
            <div className="p-0 text-center"><Link to={detailsLink} className="btn btn-sm btn-link"><img src={buildLink(product.imgsrc)} alt="product" className="short-img"/></Link></div>
            <div className="p-2 link-w"><Link to={detailsLink} className="fs-6 text-start">{parse(product.label)}</Link></div>
            <div className="ps-3"><span className="fs-5 fw-bold">{product.currency} {product.price.toFixed(2)}</span> <button className="btn btn-sm btn-warning d-inline-block align-top" type="button" onClick={() => add(product)}>Ajouter au panier</button></div>
            <div className="ps-3 fs-6 fw-light">Vendu par <span className="fw-bold">{product.selledBy}</span></div>
            <div className="ps-3 fs-6 fw-light">Livré par <span className="fw-bold">{product.deliveredBy}</span></div>
        </div>
    )
}

ShortLayout.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        deliveryPriceCoef: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        selledBy: PropTypes.string.isRequired,
        deliveredBy: PropTypes.string.isRequired,
        imgsrc: PropTypes.string
    })
}

export default ShortLayout