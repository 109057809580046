import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { changePassword } from 'commons/adapters/auth'
import useForm from 'commons/form/useForm'

const useChangePasswordScreen = () => {
    const query = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(false)
    const [failure, setFailure] = useState("")
    const [success, setSuccess] = useState("")
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            email: {
                required: {
                    value: true,
                    message: "L'adresse mail est requise!",
                },
            },
            password: {
                required: {
                    value: true,
                    message: 'Le mot de passe est requis!',
                },
                pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[-()a-zA-Z0-9@!:%._+~#=]{8,}$/,
                    message: "Le mot de passe doit contenir au moins: 8 caractères, un chiffre, un caractère majuscule et un caractère minuscule!",
                },
            },
            passwordConfirm: {
                required: {
                    value: true,
                    message: 'La confirmation du mot de passe est requise!',
                },
                custom: {
                    isValid: (value, data) => value && data && data.password && data.password === value,
                    message: "La confirmation du mot de passe est incorrecte!",
                },
            },
        },
        onSubmit: () => {
            const error = "Une erreur est survenue lors de la modification de votre mot de passe!"
            setLoading(true)
            changePassword(data.email, data.key, data.password, resp => {
                if (resp && resp["success"] && resp["message"]) {
                    setSuccess(resp["message"])
                } else if (resp && resp["message"]) {
                    setFailure(resp["message"])
                } else {
                    setFailure(error)
                }
                setLoading(false)
            }, () => {
                setFailure(error)
                setLoading(false)
            }
            )
        },
        initialValues: {
            email: query.get("email"),
            key: query.get("key"),
            password: "",
        },
    })

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    }
}

export default useChangePasswordScreen