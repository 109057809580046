import { useState, useEffect } from 'react'
import AppC from 'components/app'
import useConstants from 'commons/utils/useConstants'

const useOrderSummary = () => {
    const [shippingCost, setShippingCost] = useState()
    const [shipping, setShipping] = useState(0)
    const app = AppC.useAppContext()
    const constants = useConstants()
    const total = app.cart.reduce( (a,b) => a + ((b["pp"] * b["q"]) || 0), 0)
    const totalDC = app.cart.reduce( (a,b) => a + ((b["pdc"] * b["q"]) || 0), 0)
    const dZone = app.deliveryZone
    const eurFixe = constants.currencyFixes["EUR"]
    const deliveryZones = constants.deliveryZones
    useEffect(() => {
        if (dZone) {
            const zone = deliveryZones.filter(z => z.code === dZone)[0]
            const dCost = totalDC * zone.price * (total >= zone.dLimit ? zone.dLimitCoef : 1)
            setShipping(dCost)
            if (dCost > 0) {
                setShippingCost(dCost.toFixed(eurFixe).toString() + " €")
            } else {
                setShippingCost("GRATUITE")
            }
        } else {
            setShippingCost("-- €")
        }
    }, [deliveryZones, eurFixe, dZone, total, totalDC])
    const amount = total + shipping
    const itemsNumber = app.cart.reduce( (a,b) => a + (b["q"] || 0), 0)
    const totalPrice = total.toFixed(eurFixe)
    
    const totalVAT = (amount * 1/6).toFixed(eurFixe)
    const totalTTC = {
        EUR: amount.toFixed(eurFixe),
        XOF: (constants.changeRate["EURXOF"] * amount).toFixed(constants.currencyFixes["XOF"]),
        USD: (constants.changeRate["EURUSD"] * amount).toFixed(constants.currencyFixes["USD"]),
        BTC: (constants.changeRate["EURBTC"] * amount * constants.cryptoFeesFactor).toFixed(constants.currencyFixes["BTC"])
    }

    return [itemsNumber, totalPrice, shippingCost, totalVAT, totalTTC]
}

export default useOrderSummary