import { useState } from "react"
import { proposals } from 'commons/adapters/product'
import useForm from 'commons/form/useForm'
import AppC from 'components/app'

const useProductProposal = () => {
    const app = AppC.useAppContext()
    const [loading, setLoading] = useState(false)
    const [failure, setFailure] = useState("")
    const [success, setSuccess] = useState("")
    const urlPattern = {
        value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        message: "L'url est invalide!",
    }
    const {
        handleSubmit,
        handleChange,
        data,
        errors,
    } = useForm({
        validations: {
            url1: {
                required: {
                    value: true,
                    message: "Au moins l'url 1 est requis!",
                },
                pattern: urlPattern,
            },
            url2: {
                pattern: urlPattern,
            },
            url3: {
                pattern: urlPattern,
            },
            url4: {
                pattern: urlPattern,
            },
            url5: {
                pattern: urlPattern,
            },
            url6: {
                pattern: urlPattern,
            },
        },
        onSubmit: () => {
            const error = "Une erreur est survenue lors de l'enregistrement de votre proposition!"
            setFailure("")
            setSuccess("")
            setLoading(true)
            proposals({
                    email: app.user.email,
                    user: app.user.id,
                    urls: [data.url1, data.url2, data.url3, data.url4, data.url5, data.url6]
                }, resp => {
                    if (resp && resp["success"]) {
                        setSuccess("Votre proposition de produit est enregistrée. <br />Vous serez contacter très prochainement dès que le produit sera disponible sur assiganto.com!")
                    }else {
                        setFailure(error)
                    }
                    setLoading(false)
                }, () => {
                    setFailure(error)
                    setLoading(false)
                }
            )
        },
        initialValues: {
            url1: "",
            url2: "",
            url3: "",
            url4: "",
            url5: "",
            url6: "",
        },
    })

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    }
}

export default useProductProposal