import PropTypes from 'prop-types'
import usePriceConverter from './usePriceConverter'
import XOF from 'resources/xof.png'
import USD from 'resources/usd.png'
import BTC from 'resources/btc.png'

const PriceConverter = ({price, defaultCurrency}) => {
    const [currentPrice, currentCurrency, convert] = usePriceConverter(price)

    return (
        <div className="d-flex flex-row flex-wrap mt-1">
            <div className="pt-1 pe-1"><span className="fw-bold">{currentCurrency} {currentPrice}</span><span> ; le prix en </span></div>
            <div className="btn-group btn-group-sm">
                <button type="button" className="btn btn-outline-secondary border-0" onClick={() => convert(price, defaultCurrency, "XOF")} hidden={"XOF" === currentCurrency}>
                    <img src={XOF} width="20" height="20" alt="XOF" />XOF
                </button>
                <button type="button" className="btn btn-outline-secondary border-0" onClick={() => convert(price, defaultCurrency, "USD")} hidden={"USD" === currentCurrency}>
                    <img src={USD} width="20" height="20" alt="USD" />USD
                </button>
                <button type="button" className="btn btn-outline-secondary border-0" onClick={() => convert(price, defaultCurrency, "BTC")} hidden={"BTC" === currentCurrency}>
                    <img src={BTC} width="20" height="20" alt="BTC" />BTC
                </button>
            </div>
        </div>
    )
}

PriceConverter.propTypes = {
    price: PropTypes.number.isRequired,
    defaultCurrency: PropTypes.string
}
export default PriceConverter