import OrderSummary from 'components/order'
import SuggestBox from 'components/suggest'
import Loading from 'components/loading'
import Breadcrumb from 'components/breadcrumb'
import useOrderScreen from './useOrderScreen'

const OrderScreen = () => {
    const {
        data,
        formControlChange,
        handleSubmit,
        errors,
        savingError,
        billingAddressDifferent,
        setIsBillingAddress,
        countries,
        loading
    } = useOrderScreen()
    const invalidClass = "form-control is-invalid"
    const validClass = "form-control"
    const txtAreaPlaceholder = 'Nom et prénom(s)\nN° et libellé de la rue\nCode postal - ville\nPays\nAutres informations...'

    return (
        <div className="mb-3">
            <Breadcrumb step={2} />
            <div className="container d-flex flex-row flex-wrap justify-content-center w-100">
                <div className="card p-2 mb-3">
                    <div className="border-bottom mb-3">
                        <h3>Adresses de livraison et de facturation</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="col-md-12 mb-3 form-floating">
                            <input id="fullNameId" className={(errors.fullName ? invalidClass : validClass)} value={data.fullName || ''} onChange={formControlChange('fullName')} placeholder='Nom et prénom(s)...' />
                            <label htmlFor="fullNameId">Nom et pr&eacute;nom(s) *</label>
                            {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-md-6 mb-3 form-floating">
                                <input id="phoneId" className={errors.phone ? invalidClass : validClass} value={data.phone || ''} onChange={formControlChange('phone')} placeholder='Numéro de téléphone...' />
                                <label htmlFor="phoneId">Num&eacute;ro de t&eacute;l&eacute;phone *</label>
                                <span className="badge bg-warning text-dark text-wrap mb-2">Attention, ce num&eacute;ro doit &ecirc;tre utilis&eacute; lors du paiement par t&eacute;l&eacute;phone</span>
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>
                            <div className="col-md-6 mb-3 form-floating">
                                <select id="countryId" className={"form-select " + (errors.country ? invalidClass : validClass)} value={data.country || ''} onChange={formControlChange('country')}>
                                    <option value=''>Sélectionnez votre pays</option>
                                    {countries.map(country => (<option key={country.id.toString()} value={country.id.toString()}>{country.label}</option>))}
                                </select>
                                <label htmlFor="countryId">Pays *</label>
                                {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                            </div>
                        </div>
                        <div className="col-md-12 mb-3 form-floating">
                            <input id="addressId" className={errors.address ? invalidClass : validClass} value={data.address || ''} onChange={formControlChange('address')} placeholder='Numéro et libellé de la rue...' />
                            <label htmlFor="addressId">Num&eacute;ro et libell&eacute; de la rue *</label>
                            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                        </div>
                        {(data && data.country && (
                            (data.country === "1" && (
                                <div className="col-md-12 mb-3">
                                    <SuggestBox id="zipCodeCityId" className={errors.zipCodeCity ? invalidClass : validClass} value={data.zipCodeCity || ''} floating={true} label={"Code postal - Ville *"} 
                                        onChange={formControlChange('zipCodeCity')} placeholder='Commencez par saisir votre code postal ou ville...' />
                                    {errors.zipCodeCity && <div className="invalid-feedback">{errors.zipCodeCity}</div>}
                                </div>
                            )) || 
                                <div className="col-md-12 mb-3 form-floating">
                                    <input id="localisationId" className={errors.localisation ? invalidClass : validClass} value={data.localisation || ''} onChange={formControlChange('localisation')} placeholder="Lien de localisation google maps de l'adresse de livraison..." />
                                    <label htmlFor="localisationId">G&eacute;o-localisation</label>
                                    {errors.localisation && <div className="invalid-feedback">{errors.localisation}</div>}
                                </div>)) || 
                            <div className="col-md-12">&nbsp;</div>
                        }
                        <div className="col-md-12 mb-3 form-check">
                            <input id="billingAddressSame" type="checkbox" className="form-check-input" value="" onChange={e => setIsBillingAddress(e.target.checked)} />
                            <label htmlFor="billingAddressSame">L'adresse de facturation est différente de l'adresse de livraison</label>
                        </div>
                        {billingAddressDifferent && (
                            <div className="justify-content-md-center">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="billingAddressId">Adresse de facturation *</label>
                                    <textarea id="billingAddressId"  rows="5" style={{color:"gray"}} className={errors.billingAddress ? invalidClass : validClass} value={data.billingAddress || txtAreaPlaceholder} onChange={formControlChange('billingAddress')}
                                    onBlur={e => {if (!e.target.value) {e.target.value = txtAreaPlaceholder;e.target.style.color="gray"}}} onFocus={e => {if (e.target.value === txtAreaPlaceholder) {e.target.value = ""; e.target.style.color="black"}}}></textarea>
                                    {errors.billingAddress && <div className="invalid-feedback">{errors.billingAddress}</div>}
                                </div>
                            </div>)
                        }

                        <div className="col-md-12 justify-content-md-center">
                            {savingError && <p className="invalid">{savingError}</p>}
                            <div className="row justify-content-md-center">
                                <div className="col-md-4 mb-2">
                                    {(loading && <Loading />) || <button className="btn btn-primary" type="submit">Valider et Payer</button>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card ms-3 p-2 mb-3">
                    <OrderSummary />
                </div>
            </div>
        </div>
    )
}

export default OrderScreen