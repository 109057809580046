const useAccountValidations = () => {

    return {
        firstName: {
            required: {
                value: true,
                message: "Le prénom est requis!",
            },
            pattern: {
                value: /^[A-Za-z '-]*$/,
                message: "Le prénom ne doit contenir que de caractères alphabétiques, le trait d'union, l'espacement et l'apostrophe!",
            },
        },
        lastName: {
            required: {
                value: true,
                message: "Le nom est requis!",
            },
            pattern: {
                value: /^[A-Za-z '-]*$/,
                message: "Le nom ne doit contenir que de caractères alphabétiques, le trait d'union, l'espacement et l'apostrophe!",
            },
        },
        email: {
            required: {
                value: true,
                message: "L'adresse mail est requise!",
            },
            pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "L'adresse mail est invalide!",
            },
        },
        address: {
            required: {
                value: true,
                message: "L'adresse de domicile est requise!",
            }
        },
        country: {
            required: {
                value: true,
                message: "Le pays est requis!",
            }
        },
        zipCodeCity: {
            required: {
                isConditional: data => data.country && data.country === "1",
                message: "Le code postal ou la ville est requis(e)! Sélectionnez votre code postal - ville dans les suggestions proposées...",
            },
        },
        localisation: {
            pattern: {
                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                message: "Le lien de géolocalisation est invalide!",
            },
        },
        phone: {
            required: {
                value: true,
                message: "Le numéro de téléphone est requis!",
            },
            pattern: {
                value: /^[0-9]{8,}$/,
                message: "Le numéro de téléphone doit contenir au moins 8 chiffres!",
            },
        },
    }
}

export default useAccountValidations