import Loading from 'components/loading'
import AccountInfos from 'components/account'
import usePersonalInfo from './usePersonalInfo'

const PersonalInfo = () => {
    const {
        data,
        handleChange,
        handleSubmit,
        errors,
        failure,
        success,
        loading
    } = usePersonalInfo()

    return (
        <div className="p-2 mb-3">
            <div className="border-bottom mb-3">
                <h3>Modification de mes informations personnelles</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <AccountInfos forRegistration={false} data={data} errors={errors} onChange={handleChange} />
                <div className="row justify-content-md-center">
                    {(failure && <p className="invalid">{failure}</p>) || (success && <p className="valid">{success}</p>)}
                    <div className="col-md-4">
                        {(loading && <Loading />) || <button className="col-md-12 btn btn-primary" type="submit">Valider</button>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PersonalInfo